//考勤与评价
<template>
  <div class="class_evaluation_attendance">
    <Layout :showLoading="showLoading">
      <template v-slot:content>
        <div class="class_detail_content">
          <!-- 教师姓名手机号 -->
          <div class="margin_top_20 margin_bom_10">
            <span>班级名称 : {{ item.class_name }}</span>
            <span class="margin_left_20">班级ID : {{ item.class_id }}</span>
            <span class="margin_left_20">班级类型 : {{ item.type == 1 ? "正式课" : "试听课" }}</span
            ><!--班级类型（1正常班级 0试听班级） -->

            <span class="margin_left_40"
              >课程名称 : {{ courseName }}
              <el-select v-model="courseId" v-if="false" :disabled="true" placeholder="请选择" size="small">
                <el-option v-for="(item, index) in subList" :key="item.subId" :label="item.subName" :value="item.subId" @click.native="changeCourse1(index)">
                </el-option>
              </el-select>
            </span>

            <span class="margin_left_40"
              >课程级别 : {{ levelName }}
              <el-select v-model="levelId" v-if="false" :disabled="true" placeholder="请选择" size="small">
                <el-option
                  v-for="(item, index) in levelList"
                  :key="item.levelId"
                  :label="item.levelName"
                  :value="item.levelId"
                  @click.native="changeCourse2(index)"
                >
                </el-option>
              </el-select>
            </span>

            <span class="margin_left_40"
              >主题名称 : 
              <el-select v-model="lessonId" v-if="isLessonId" placeholder="请选择" size="small">
                <el-option 
                :style="{ 'color': lessonId==item.lessonId||lessonId==''||isEvaluate ? '' : '#B0B0B0' }" 
                :disabled="(lessonId!=item.lessonId && !isEvaluate)"
                v-for="item in lessonList" :key="item.lessonId" :label="item.lessonName" :value="item.lessonId"> </el-option>
              </el-select>
            </span>

            <div class="margin_top_10">
              <span v-if="role == '3'" class="margin_right_30">班级老师 : {{ item.teacher_name }}</span>
              <span>实招/预招 : {{ item.class_num }}/{{ item.plan_num }}</span>

              <span class="margin_left_30">是否限制入班人数 : {{ item.limit_plan == 1 ? "是" : "否" }}</span>
            </div>
          </div>

          <!-- 考勤与评价 -->
          <AttendanceEvaluation v-if="isHttp" :item="item" :courseId="courseId" :levelId="levelId" :lessonId="lessonId" @getCourse="getCourseID"></AttendanceEvaluation>
        </div>
      </template>
    </Layout>
  </div>
</template>
<script>
//考勤与评价组件
import AttendanceEvaluation from "@/views/educationalcenter/classmanager/AttendanceEvaluation";

export default {
  data() {
    return {
      isHttp:false,
      //数据
      item: {},

      //是否显示加载布局
      showLoading: 1,
      subList: [],
      levelList: [],
      lessonList: [],
      //选中课程ID
      courseId: "",
      //级别课程ID
      levelId: "",
      //课次ID
      lessonId: "",
      //角色-1游客 0学生  1老师 2校区教务 3校长 4管理员 5超级管理员
      role: "",
      courseName: "",
      levelName: "",
      cid: "",
      //是否提交了评价
      isEvaluate:true,
      //是否有级别
      isLessonId:true,
    };
  },
  created() {
    //班级ID
    this.item = JSON.parse(this.$route.query.item);

    this.cid = this.item.class_id;
    //-1游客 0学生  1老师 2校区教务 3校长 4管理员 5超级管理员
    this.role = this.getUserInfo().role;
    this.httpData();
  },
  mounted() {
    this.httpData();
  },
  methods: {
    getCourseID(val) {
      this.lessonId = "";
      console.log(this.item);
      console.log(val);
      this.isEvaluate = true
      //是否提交了评价
      val.studentRecord.infoList.forEach(element => {
        if(element.evaluate_id!= -1&&element.evaluate_id!=null){
          this.isEvaluate = false
        }
      });
      console.log(this.isEvaluate);
      //遍历筛选课程和级别,课次集合
      this.itemData.subList.forEach((item1, index) => {
        if (val.subId == item1.subId) {
          console.log(index);
          this.courseId = item1.subId;
          this.courseName = item1.subName;
          this.subList.splice(0, this.subList.length);
          this.subList.push(this.itemData.subList[index]);

          console.log(this.subList);
          this.subList[0].levelList.forEach((item2, index1) => {
            if (val.levelId == item2.levelId) {
              console.log(index1);
              this.lessonList = item2.lessonList;

              this.levelName = item2.levelName;

              this.levelId = item2.levelId;
              this.levelList.splice(0, this.levelList.length);
              this.levelList.push(this.subList[0].levelList[index1]);
            }
          });
        }
      });
      
      if(val.lessonId!=-1&& !this.isEmpty(this.levelName)){
        this.lessonId = val.lessonId;
      }
      //有级别才显示主题
      if(this.isEmpty(this.levelName)){
        this.isLessonId = false;
      }

    },
    //课程改变的值
    changeCourse1(val) {
      console.log(val);

      this.levelList = this.itemData.subList[val].levelList;
      this.levelId = "";
      this.lessonId = "";
    },
    //选中级别的回调
    changeCourse2(val) {
      console.log(val);
      this.lessonList = this.levelList[val].lessonList;

      this.lessonId = "";
    },

    //获取班级信息
    httpData() {
      let param = this.getHttpParams();
      param.type = 5;
      param.cid = this.item.class_id;

      this.$http
        .fetchPost(this.$api.CLASSMANAGER, param)
        .then((res) => {
          //显示内容视图
          this.showLoading = 1;
          let resulte = res.data;
          this.isHttp = true;
          if (resulte.state == 200) {
            //获取列表数据
            this.itemData = resulte.data;

            //课程id
            // this.courseId = this.item.subList[0].subId;

            // this.levelList = this.item.subList[0].levelList;
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.showLoading = 1;
          this.isHttp = true;
          console.log("请求数据异常");
        });
    },
  },
  computed: {},
  components: {
    AttendanceEvaluation,
  },
};
</script>
<style lang="scss" scoped>
.class_evaluation_attendance {
  height: calc(100vh - 77px);
  margin: 0 auto;
  width: 96%;

  .class_detail_content {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;

    .appraise_font {
      color: #ff9100;
    }

    ::v-deep .el-table .cell {
      text-align: center;
    }

    //搜索部分
    .action {
      height: 54px;
      display: flex;
      align-items: center;
      min-width: 1100px;
      ::v-deep .el-tabs__active-bar {
        background-color: transparent !important;
        line-height: 10px;
      }
      ::v-deep.el-tabs__nav-wrap::after {
        position: static !important;
      }

      ::v-deep .el-input__inner {
        height: 32px;
        line-height: 32px;
        padding-left: 6px;
      }

      ::v-deep .el-input__icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      ::v-deep .el-input {
        width: 210px;
      }

      ::v-deep .el-input__suffix {
        right: 0px;
      }

      .dv_time {
        display: flex;
        align-items: center;
        ::v-deep .el-range-editor.el-input__inner {
          display: flex;
          align-items: center;
          padding: 0 6px;
          width: 320px;
        }
        ::v-deep {
          .el-date-editor .el-range-input {
            width: 120px;
          }
        }
      }
    }
  }
}
</style>
